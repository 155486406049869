<template>
    <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            
            <li :class="'nav-item ' + (data.sub_modules.length > 0?'has-treeview':'')" v-for="(data, x) in modules" :key="modules.id">
                <router-link :to="'/'+ data.slug" class="nav-link" v-if="data.sub_modules.length == 0 && hasRequiredPermission(data.permission_names)">
                    <i :class="'nav-icon '+ data.icon"></i>
                    <p>
                        {{ data.name }}
                    </p>
                </router-link>
                <a href="javascript:void(0);" class="nav-link" v-if="data.sub_modules.length > 0">
                    <i :class="'nav-icon '+ data.icon"></i>
                    <p>
                        {{ data.name }}
                        <i class="right fas fa-angle-left"></i>
                    </p>
                </a>
                <ul class="nav nav-treeview" v-if="data.sub_modules.length > 0" style="">

                    <li class="nav-item" v-for="(data2, x2) in data.sub_modules" v-if="hasRequiredPermission(data2.permission_names)">
                        <router-link :to="'/' + data2.slug" class="nav-link">
                            <i :class="'nav-icon '+ data2.icon"></i>
                            <p>
                                {{ data2.name }}
                            </p>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        modules: []
      };
    },
    mounted() {
      axios.get('/api/module')
        .then(response => {
          this.modules = response.data.data;
          // console.log(this.modules);
        })
        .catch(error => {
          console.error('Error fetching modules:', error);
        });
    },
    methods: {
      hasRequiredPermission(permissionNames) {
        if (!permissionNames || !Array.isArray(permissionNames)) {
            return false;
        }
        return permissionNames.some(permission => permission.required === "yes");
      },
      route(name) {
        return this.$route(name);
      }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>